<template>
  <div :class="['editOrderBox', hideEditPage && 'hideEditPage']">
    <iHeader
      backType="blank"
      :headStyle="
        ['msd', 'dsm'].includes($cookies.get('tenant'))
          ? {
              background: '#fff',
              color: '#13161B',
            }
          : {
              background: 'var(--themeColor)',
              color: '#fff',
            }
      "
      :backEvent="true"
      @back-event="editOrderGoBack()"
      :back="true"
    >
    <div slot="headText">
      <span class="head-txt"> 编辑订单</span>
    </div>
    </iHeader>
    <!-- <div class="publicHead">
      <p>编辑订单</p>
    </div> -->
    <div class="contentBox">
      <div class="normalInputRow" @click="openComponent('carRule')">
        <MInput
          title="用车形式"
          noCancel="false"
          disabled="true"
          v-model="params.carRuleTxt"
          :must="true"
          pla="请选择用车形式"
        ></MInput>
      </div>
      <div>
        <div class="normalInputRow" @click="openUseDate('useDate')">
          <MInput
            title="出发时间"
            noCancel="false"
            v-model="params.departure_time"
            disabled="true"
            :must="true"
            pla="请选择出发时间"
          ></MInput>
        </div>
      </div>
      <!-- <div v-if="params.carRule == 2">
        <div class="normalInputRow" @click="openComponent('useDays')">
          <MInput
            title="包车天数"
            noCancel="false"
            must="true"
            disabled="true"
            v-model="params.useDays"
            pla="请选择包车天数"
          ></MInput>
        </div>
      </div> -->
      <div class="normalInputRow departure" @click="opMap('start_name')">
        <div class="departure_label">
          <i class="greenBc"></i>
          <span>出发地</span>
          <span class="must">*</span>
        </div>
        <input type="text" v-model="params.start_name" class="departure_inner" placeholder="请选择出发地" :disabled="true">
      </div>
      <div class="normalInputRow departure" @click="opMap('end_name')" v-if="params.carRule != 2" >
        <div class="departure_label">
          <i class="orangeBc"></i>
          <span>目的地</span>
          <span class="must">*</span>
        </div>
        <input type="text" v-model="params.end_name" class="departure_inner" placeholder="请选择目的地" :disabled="true">
      </div>
      <div v-if="params.carRule == '3' || params.carRule == '4'">
        <div class="normalInputRow">
          <MInput
            title="航班号"
            noCancel="false"
            v-model="params.flt"
            pla="请输入航班号"
            :must="true"
          ></MInput>
        </div>
      </div>
      <div class="passengerBox">
        <div class="normalInputRow">
          <MInput
            title="乘车联系人"
            noCancel="false"
            v-model="params.passenger_name"
            pla="请输入乘车人姓名"
            :must="true"
          ></MInput>
        </div>
        <div class="normalInputRow">
          <MInput
            type="number"
            noCancel="false"
            title="乘车人联系电话"
            v-model="params.passenger_phone"
            pla="请输入乘车人手机号"
            :must="true"
          ></MInput>
        </div>
      </div>
      <div>
        <div class="normalInputRow" >
          <MInput
            v-if="['pfizer'].includes($cookies.get('tenant'))"
            type="number"
            title="实际用车人数"
            noCancel="false"
            :disabled="(params.ProposalStatus != 0 && params.isOrderFreeze )"
            v-model="params.qty"
            pla="请输实际用车人数"
            :must="true"
          ></MInput>
        </div>
      </div>
      <div class="normalInputRow">
        <MInput
          :title="'备注'"
          :must="false"
          noCancel="false"
          v-model="params.desc"
          :pla="'请输入备注'"
        ></MInput>
      </div>
    </div>
    <div class="bottom_btns">
      <div class="editBtn" @click="GetPriceCoupon()">
        预估报价
    </div>
    </div>
    
    <Select
      val="value"
      :clickClose="true"
      :title="selecttTtle"
      @on-ok="chooseSelect"
      :selectList="selectList"
      ref="selectRef"
    >
      <div
        slot="selectLeft"
        @click="choiseCity"
        v-if="
          selectOpen != 'carRule' &&
          (params.carRule == 3 || params.carRule == 4)
        "
        style="width: 1rem"
      >
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icondaohang-tianchong" />
        </svg>
        {{ cityDictVal }}
      </div>
    </Select>
    <Popup
      v-model="showDataPicker"
      position="bottom"
      :style="{ height: '38%' }"
    >
      <DatetimePicker
        ref="datePicker"
        :formatter="formatter"
        type="datetime"
        :filter="filter"
        :title="dateTitle"
        :min-date="startDate"
        @confirm="chooseSelect"
        @cancel="showDataPicker = false"
      ></DatetimePicker>
    </Popup>
    <Map ref="mapPop" @close="close" />
    <SearchCityCar
      @on-ok="chooseCity"
      :cityList="cityList"
      v="2.0"
      ref="eventCity"
    ></SearchCityCar>
    <chosePhonePerson
      ref="chosePhonePerson"
      @chosePPerson="chosePPerson"
    ></chosePhonePerson>
    <choseAttender
      ref="choseAttender"
      @chosePPerson="chosePPerson"
    ></choseAttender>
    <addPassenger
      @getPassengerItem="getPassengerItem"
      :passengerName="params.passenger_name"
      ref="addPassenger"
    ></addPassenger>
    <div class="dialogbox" v-if="isAttenderDialog">
      <div class="maskBox"></div>
      <div class="brnBox">
        <span class="closeBtn" @click.stop="isAttenderDialog = false">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconclose" />
          </svg>
        </span>
        <div class="choseBtn">
          <a @click="choseAttender('QueryOftenAttender','常用参会人')">常用参会人</a>
          <a @click="choseAttender('OutPagingByMarker','HCP(CRM)')">HCP（CRM客户）</a>
          <a @click="choseAttender(4,'HCP(非CRM)')">HCP（非CRM客户）</a>
          <a @click="choseAttender(3),'外部非CRM'">外部非HCP客户</a>
          <a @click="choseAttender('InPagingByMarker','ROCHE员工(China)')">罗氏（中国）员工</a>
          <a @click="choseAttender(5,'ROCHE员工(非China)')">罗氏（非中国）员工</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import filterEmoji from "@/views/controllers/filterEmoji.js";
import chosePhonePerson from "@/views/common/chosePhonePerson";
import choseAttender from "@/views/common/choseAttender";
import addPassenger from "@/views/common/addPassenger";
import vueFilter from "@/views/controllers/filter/vuefilter.js";
import { platonlineformOrder as validator } from "@/views/controllers/carValidatorConfig";
import Map from "@/views/common/map/map"
import SearchCityCar from "@/views/common/SearchCityCar/SearchCityCar"
import { DatetimePicker, Popup } from "vant";
import "vant/lib/index.css";
export default {
  mixins: [filterEmoji],
  data() {
    return {
      isDsmApproval: ['dsm'].includes(this.$cookies.get('tenant')) && this.SmartStorage.get('eventData').userEventType == 'approval',
      isAttenderDialog: false,
      hideEditPage: false,
      showDataPicker: false,
      endDate: "",
      dateTitle: "",
      startDate: "",
      cityList: [],
      passengerList: [],
      cityDictVal: this.SmartStorage.get("eventData").cityDictVal,
      selecttTtle: "",
      selectList: [],
      airportList: [],
      carTypeList: [],
      flagEidt: false,
      selectOpen: "",
      params: {
        start_name: "",
        carRule: 0,
      },
    };
  },
  components: {
    Map,
    SearchCityCar,
    Popup,
    DatetimePicker,
    chosePhonePerson,
    choseAttender,
    addPassenger,
  },
  beforeDestroy() {
    this.params = null;
    this.cityList = null;
    this.map && this.map.destroy();
    // this.SmartStorage.remove("editdataForm");
    this.SmartStorage.remove("priceCouponData");
    this.$root.$eventHub.$off("editOrderGoBack");
  },
  created() {
    let now = new Date();
    this.startDate = new Date(now.getTime() + 1000 * 60 * 30);
    this.GetCarService();
    let onlineData = this.SmartStorage.get("onlineData");
    let editdataForm = this.SmartStorage.get("editdataForm");
    if (!this.$route.meta.isBack && editdataForm || onlineData) {
      let now = new Date();
      this.startDate = new Date(now.getTime() + 1000*60*30);
      this.endDate = new Date(new Date().setDate(new Date().getDate() + 2));
      this.getCity();
      //加入页面初始化的逻辑
      if (editdataForm && editdataForm.itsItemData) {
        editdataForm.itsItemData.start_name = editdataForm.itsItemData.end_name?editdataForm.itsItemData.start_name : "";
        this.orderList(editdataForm);
      }else if(onlineData){
        this.params = onlineData;
      }else{
        this.params = {
          start_name: "",
          carRule: 0,
          passenger:'',
        };
      }
    } else {
      this.dateTitle = "";
      this.params.flight_date = "";
    }

    this.setRouteListeners("editOrderGoBack");
    this.$root.$eventHub.$on("editOrderGoBack", (data) => {
      this.editOrderGoBack();
      this.$root.$eventHub.$off("editOrderGoBack"); //解绑当前监听，否则下轮监听还会持续叠加
    });
  },
  methods: {
    async QueryPassenger(data) {
      let params = {
        BusinessId: data.ItemId,
      };
      await this.services.QueryPassenger(params).then((res) => {
        if (res.success) {
          res.content.forEach((element, index) => {
            if (
              element.passengers == data.itsItemData.passenger.name &&
              element.department == data.itsItemData.passenger_department
            ) {
              res.content.splice(index, 1);
            }
            element.passengers = this.StringExchangeEmoji(element.passengers);
            element.department = this.StringExchangeEmoji(element.department);
          });
          this.passengerList = res.content;
        }
      });
    },
    removePassenger(index) {
      this.passengerList.splice(index, 1);
    },
    goAddPassenger(data) {
      this.flagEidt = data ? true : false;
      this.$refs.addPassenger.showPopup(data);
    },
    getPassengerItem(data) {
      !this.flagEidt && this.passengerList.push(data);
    },
    choseAttender(type,nameType) {
      this.$refs.choseAttender.showPopup({
        name: this.params.passenger_name,
        phoneNumber: this.params.passenger_phone,
        type: type,
        nameType:nameType
      });
      this.isAttenderDialog = false;
    },
    showPhone() {
      this.$refs.chosePhonePerson.showPopup({
        name: this.params.passenger_name,
        phoneNumber: this.params.passenger_phone,
      });
      this.hideEditPage = true;
    },
    chosePPerson(data) {
      this.params.passenger_name = data.name;
      if (data.phoneNumber) {
        data.phoneNumber = data.phoneNumber.toString().replace(/-/g, "");
        this.params.passenger_phone = data.phoneNumber
          .toString()
          .replace(/\s*/g, "");
      }

      this.$forceUpdate();
    },
    filter(type, options) {
      if (type === "minute") {
        return options.filter((option) => option % 5 === 0);
      }
      return options;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}`;
      } else if (type === "month") {
        return `${val}`;
      }
      return val;
    },
    getCity() {
      let params = {
        keyCode: "CPC-City",
      };
      let _this = this;
      this.services.queryDict(params).then((res) => {
        if (res.success) {
          _this.cityList = res.content;
        }
      });
    },
    close(data) {
      this.initForm();
    },
    choiseCity() {
      this.$refs.eventCity.openPop();
    },
    chooseCity(e) {
      if (e) {
        this.cityDictVal = e.val;
        this.geocoderGps(e);
      }
    },
    orderList(editdataForm) {
      let data = editdataForm.itsItemData;
      this.params = data;
      this.params.passenger = data.passenger;
      this.params.itemId = editdataForm.itemId;
      this.params.start_name = data.puAddress
      this.params.end_name = data.destAddress
      this.params.isOrderFreeze = data.isOrderFreeze || false
      this.params.ProposalStatus = editdataForm.ProposalStatus || editdataForm.proposalStatus || 0
      if (data.carRule == 2) {
        this.params.end_address = "";
        this.params.destAddress = "";
        this.params.tlng = 0;
        this.params.tlat = 0;
        this.params.destCity = "";
        this.params.end_name = "";
      }
      this.$forceUpdate();
    },
    getEmployee() {
      let _this = this;
      this.services.getEmployee({}).then((res) => {
        if (res.success) {
          _this.params.conditionData = [
            { PositionName: res.content.positionName },
          ];
        }
      });
    },
    editOrderGoBack() {
      this.removeRouteListeners("itemsGoBack");
      this.$store.commit("setValue", {
        key: "homeWait",
        value: {
          date: new Date(),
          type: "back",
        },
      });
      this.passengerList = [];
      this.SmartStorage.remove("editdataForm")
      this.$router.push("/onlineRental")
    },
    async GetPriceCoupon() {
    
      this.$store.commit(
        "SETpassengerList",
        this.passengerList.length > 0 ? this.passengerList : false
      );
      console.log('this.params=======',this.params)
      var validateResult = this.validate(this.params, validator);
     
      if (!validateResult) return;
      let reg = /^[0-9a-zA-Z]+$/;
      let pasengerReg = /^((?!教授|老师|医生|主任).)*$/;
      if (
        this.params.passenger_name &&
        !pasengerReg.test(this.params.passenger_name)
      ) {
        this.$iToast(
          "乘车人姓名中不能包含“教授、老师、医生、主任”等，请录入乘车人全名"
        );
        return false;
      }
      if (
        this.params.departure_time &&
        new Date().getTime() + 9000000 >= new Date(this.params.departure_time.replace(/-/g, "/")).getTime()
      ) {
        this.$iToast("请至少提前150分钟预约用车");
        return false;
      }
      let data = new Date(this.params.departure_time).getTime()/1000
      let startData = new Date(this.SmartStorage.get("eventData").dtStart).getTime()/1000 - 86400
      let endData = new Date(this.SmartStorage.get("eventData").dtEnd).getTime()/1000 + 86400*2
      if(data > endData || data < startData){
        this.$iToast("用车时间不在会议允许范围内");
        return false;
      }
      this.params.passenger_name = this.EmojiExchangeString(
        this.params.passenger_name
      );
      if(this.isDsmApproval&&(this.params.desc==undefined||this.params.desc=='')){ 
        this.$iToast('请填写用车事由');
        return false;
      }
      this.params.desc = this.EmojiExchangeString(this.params.desc);
      if (["pfizer"].includes(this.$cookies.get("tenant"))) {
        this.params.conditionData = [
          { TypeDictTxt: this.SmartStorage.get("eventData").typeDictTxt || this.SmartStorage.get("eventData").typeDictVal },
        ];
      }
      // this.SmartStorage.set('editdataForm',{itsItemData:this.params})
      this.SmartStorage.set('onlineData',this.params)
      this.$router.push(`/onlinePlatformPriceCoupon`);
      // }
      localStorage && localStorage.setItem("_AMap_AMap.IndoorMap", "");
    },
    //打开高德h5
    async openGaode(itemId) {
      let params = {
        itemId: itemId,
      };
      this.services.GetOrderUrl(params).then((res) => {
        if (res.success) {
          let gaodearry = ["amap.com", "smartmice", "#/publish"];
          this.gosafetyCenter(
            "",
            res.content.url,
            this.ESWebappBack,
            gaodearry
          );
        }
      });
    },
    async ESWebappBack() {
      let params = {
        proposalId: this.SmartStorage.get("proposalId"),
        itemId: this.params.itemId,
      };
      this.services.GetOnlineDetail(params).then((res) => {
        if (res.success) {
          res.content.extOrderId && this.$router.push("/platformIndex");
        }
      });
    },
    GetCarService() {
      let _this = this;
      let params = {
         conditionData:[
          {TypeDictTxt: this.SmartStorage.get("eventData").typeDictTxt || this.SmartStorage.get("eventData").typeDictVal || ''}
        ]
      }
      this.services.GetCarRules(params).then((res) => {
        if (res.success) {
          res.content.forEach((element) => {
            element.value = element.carRuleTxt;
          });
          _this.carTypeList = res.content;
        }
      });
    },
    //更新表单信息
    initForm(choiseItem) {
      if (this.type == "start_name" && this.$store.state.extdata.latitude) {
        this.params.start_type = this.$store.state.extdata.type;
        this.params.flat = this.$store.state.extdata.latitude;
        this.params.flng = this.$store.state.extdata.longitude;
        this.params.start_name = this.$store.state.extdata.addressName;
        this.params.start_city = this.$store.state.extdata.city;
        this.params.puAddress =
          this.$store.state.extdata.address +
          this.$store.state.extdata.addressName;
        this.params.startCityId = this.$store.state.extdata.cityId;
      } else if (
        this.type == "end_name" &&
        this.$store.state.extdata.latitude
      ) {
        this.params.end_type = this.$store.state.extdata.type;
        this.params.tlat = this.$store.state.extdata.latitude;
        this.params.tlng = this.$store.state.extdata.longitude;
        this.params.end_name = this.$store.state.extdata.addressName;
        this.params.destCity = this.$store.state.extdata.city;
        this.params.end_city = this.$store.state.extdata.city;
        this.params.destAddress =
          this.$store.state.extdata.address +
          this.$store.state.extdata.addressName;
        this.params.endCityId = this.$store.state.extdata.cityId;
      } else if (choiseItem) {
        this.params.passenger.uci = choiseItem.uci;
        this.params.passenger.id = choiseItem.id;
        this.params.passenger.typeVal = choiseItem.typeVal;
        this.params.passenger_name = choiseItem.name || choiseItem.cName;
        this.params.passenger.chrisID = choiseItem.chrisID;
      }
      this.$forceUpdate();
    },
    checkTime(datatime) {
      let data = this.SmartStorage.get("checkCanNewItem");
      if (
        new Date(datatime.replace(/-/g, "/").substr(0, 10)) >
          new Date(data.useEndDate.replace(/-/g, "/").substr(0, 10)) ||
        new Date(datatime.replace(/-/g, "/").substr(0, 10)) <
          new Date(data.useStartDate.replace(/-/g, "/").substr(0, 10))
      ) {
        return true;
      }
      return false;
    },
    chooseSelect(e) {
      let flag = false;
      let datatime = vueFilter.formatDate(e, "yyyy-MM-dd hh:mm");

      switch (this.selectOpen) {
        case "carRule":
          this.params.carRuleTxt = e.carRuleTxt;
          this.params.carRule = e.carRule;
          this.params.end_name = [4,5,6].includes(e.carRule) ? "" : this.params.end_name;
          this.params.start_name = [3].includes(e.carRule) ? "" : this.params.start_name;
          [3,4].includes(e.carRule) && this.geocoderGps();
          if ([5,6].includes(e.carRule)) {
            this.params.end_address = "";
            this.params.destAddress = "";
            this.params.tlng = 0;
            this.params.tlat = 0;
            this.params.destCity = "";
          }
          this.$set(this.params, "carRule", e.carRule);
          this.$set(this.params, "carRule_txt", e.carType);
          break;
        case "useDays":
          if (
            this.SmartStorage.get("checkCanNewItem").useStartDate &&
            e.value != 0.5
          ) {
            let now = new Date(
              this.params.departure_time.replace(/-/g, "/").substr(0, 10)
            );
            let usedatatime = vueFilter.formatDate(
              now.setDate(now.getDate() + e.value),
              "yyyy-MM-dd hh:mm"
            );
            if (this.checkTime(usedatatime)) {
              this.$iMessageBox.render({
                btnType: "single",
                singleTxt: "确定",
                messageTitle: "提示",
                messageTxt:
                  "用车时间超出可用时间范围：" +
                  this.SmartStorage.get("checkCanNewItem").useStartDate +
                  "~" +
                  this.SmartStorage.get("checkCanNewItem").useEndDate +
                  "，不可用车",
              });
              return false;
            }
          }
          this.$set(this.params, "useDays", e.value);
          break;
        case "useDate":
          this.showDataPicker = false;
          if (
            this.params.carRule == "12" &&
            this.params.useDays &&
            this.params.useDays != 0.5
          ) {
            let useDate2 = new Date(datatime.replace(/-/g, "/").substr(0, 10));
            datatime = vueFilter.formatDate(
              useDate2.setDate(useDate2.getDate() + this.params.useDays),
              "yyyy-MM-dd hh:mm"
            );
          }
          this.$set(this.params, "departure_time", datatime);
          break;
        case "flyDate":
          this.showDataPicker = false;
          this.$set(this.params, "flight_date", datatime);
          this.GetPriceCoupon();
          break;
        case "Airport":
          if (this.params.carRule && this.params.carRule == 3) {
            this.params.start_name = e.airport_name;
            this.params.start_type = "交通设施服务;机场相关;飞机场";
            this.params.start_city = e.city_name;
            this.params.flat = e.lat;
            this.params.flng = e.lng;
            this.params.startCityId = e.city;
            this.params.puAddress = e.airport_name;
          } else {   
            this.params.end_name = e.airport_name;
            this.params.end_type = "交通设施服务;机场相关;飞机场";
            this.params.end_city = e.city_name;
            this.params.tlat = e.lat;
            this.params.tlng = e.lng;
            this.params.endCityId = e.city;
            this.params.destAddress = e.airport_name;
          }
          this.params.airCode_txt = e.airport_name;
          this.params.airCode = e.code;
          this.$forceUpdate();
          break;
        default:
          break;
      }
    },
    onchangePicker(e) {
    },
    async openComponent(type) {
      this.selectOpen = type;
      switch (type) {
        case "carRule":
          if(this.params.ProposalStatus != 0 && this.params.isOrderFreeze){
            return
          }
          this.selectList = this.carTypeList;
          this.selecttTtle = "请选择用车形式";
          break;
        case "Airport":
          if(this.airportList.length == 0){
            await this.GetCityAirportsp()
          }
          this.selectList = this.airportList;
          this.selecttTtle = "请选择机场";
          break;
        default:
          break;
      }
      this.$refs.selectRef.openPop();
      // isSelectRef ? this.$refs.selectRef.openPop() : this.$refs[type].openPop();
      // this.type=='useDate'?this.dataVisible=true:(isSelectRef ? this.$refs.selectRef.openPop() : this.$refs[type].openPop());
    },
    openUseDate(type) {
      this.showDataPicker = true;
      this.selectOpen = type;
    },
    async GetCityAirportsp(location) {
      let params = {
        siteType: "0",
        city: this.cityDictVal,
      };
      let _this = this;
      return new Promise((resolve, reject) => {
        this.services.OnlineGetOrderLocation(params).then((res) => {
          if (res.success) {
            res.content.forEach((element) => {
              element.value = element.airport_name;
            });
            _this.airportList = res.content;
            _this.selectList = res.content;
            resolve(true)
          }else{
            reject(res.msg)
          }
        });
      })
    },
    GetCityAirports(location) {
      this.$iDelay(() => {
        this.GetCityAirportsp(location);
      }, 200);
    },
    //将城市转经纬度
    geocoderGps(e) {
      let _this = this;
      if (
        !e &&
        ["dsm"].includes(this.$cookies.get("tenant")) &&
        this.SmartStorage.get("eventData").userEventType == "approval"
      ) {
        _this.cityDictVal =
          this.SmartStorage.get("eventData").cityDictVal.split(",")[0];
      }
      let AMap = (this.AMap = window.AMap);
      AMap.plugin("AMap.Geocoder", function () {
        var geocoders = new AMap.Geocoder({});
        geocoders.getLocation(_this.cityDictVal, function (status, result) {
          if (status === "complete" && result.info === "OK") {
            _this.GetCityAirports(result.geocodes[0].location);
          } else {
            _this.cityDictVal = "北京市";
            _this.geocoderGps();
          }
        });
      });
    },
    opMap(type, index) {
      if (this.params.carRule == 3 && type == "start_name") {
        this.openComponent("Airport");
        // this.geocoderGps();
      } else if (this.params.carRule == 4 && type == "end_name") {
        this.openComponent("Airport");
        // this.geocoderGps();
      } else {
        this.type = type;
        this.$refs.mapPop.open(
          type == "start_name"
            ? {
                longitude: this.params.flng,
                latitude: this.params.flat,
                name: this.params.start_name,
                city: this.params.puCity,
              }
            : {
                longitude: this.params.tlng,
                latitude: this.params.tlat,
                name: this.params.end_name,
                city: this.params.destCity,
              }
        );
      }
    },
  },
};
</script>
<style lang="sass">
@import '@/assets/platform/index.scss'
</style>